import { Route, Routes } from "react-router-dom";
import Sites from "./containers/Sites";
import Attachments from "./containers/Attachments";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/site/attachments" element={<Attachments />} />
      <Route path="/site" element={<Sites />} />
    </Routes>
  );
};

export default Router;
