import { useContext } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {
  rowStyle,
  labelBoxStyle,
  detailsBoxStyle,
  typographyStyle,
  typography2Style,
} from "../../Sites/SiteDetails/utils";
import {
  CloseIcon24p,
  DarkModeContext,
  getRequiredDateFormat,
} from "@datwyler/shared-components";

const AttachmentInfoDialog = (props) => {
  const { isOpen = false, closeDialog, attachmentInfo } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const format = attachmentInfo?.filename?.split(".").pop();

  const font1Style = { ...typographyStyle, color: colors.siteDetailsFont1 };
  const font2Style = { ...typography2Style, color: colors.siteDetailsFont2 };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "attachment_info" })}
        </Typography>
        <IconButton
          onClick={closeDialog}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent>
        <Box sx={{ paddingBottom: "24px" }}>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "name" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>{attachmentInfo?.name}</Typography>
            </Box>
          </Box>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "description" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>
                {attachmentInfo?.description}
              </Typography>
            </Box>
          </Box>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "format" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>{format}</Typography>
            </Box>
          </Box>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "size" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>
                {attachmentInfo?.filesize}
              </Typography>
            </Box>
          </Box>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "uploaded_by" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>
                {attachmentInfo?.createdBy?.firstName +
                  " " +
                  attachmentInfo?.createdBy?.lastName}
              </Typography>
            </Box>
          </Box>
          <Box sx={rowStyle}>
            <Box sx={labelBoxStyle}>
              <Typography sx={font1Style}>
                {intl.formatMessage({ id: "uploaded_on" })}
              </Typography>
            </Box>
            <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
              <Typography sx={font2Style}>
                {getRequiredDateFormat(
                  attachmentInfo?.createdOn,
                  "DD/MM/YYYY HH:mm"
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      {/*<DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
                <Box sx={{ marginRight: "16px" }}>
                <BasicButton onClick={closeDialog} variant={"outlined"}>
                    {intl.formatMessage({ id: "cancel" })}
                </BasicButton>
                </Box>
                <Box sx={{ marginRight: "16px" }}>
                <BasicButton
                    onClick={handleUpload}
                    variant={"contained"}
                    sx={{ backgroundColor: colors.datwylerTeal }}
                    disabled={!siteId || !name || !description || !file}
                >
                    {intl.formatMessage({ id: "upload" })}
                </BasicButton>
                </Box>
            </DialogActions>*/}
    </Dialog>
  );
};

export default AttachmentInfoDialog;
