import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/Error";
import {
  textFieldInputProps,
  inputLabelProps,
  isDeviceInSites,
} from "../utils";
import { ACTIVE } from "../../../utils/constants";
import {
  AddIcon,
  CloseIcon24p,
  BasicTextFieldWithoutForm,
  useSnackbar,
  BasicButton,
  DarkModeContext,
} from "@datwyler/shared-components";

const SiteComponent = (props) => {
  const {
    sites,
    setSites,
    index,
    handleRemoveSite,
    site,
    form,
    intl,
    siteCount,
  } = props;

  const {
    formState: { errors },
  } = form;
  const { colors }: any = useContext(DarkModeContext);

  const handleOnChangeName = (val) => {
    if (val.length > 30) {
      form.setError(`sites_${index}_name`, {
        type: "maxLength",
        message: intl.formatMessage({ id: "validation_maxlength" }),
      });
      return;
    } else {
      form.clearErrors(`sites_${index}_name`);
    }
    const tempSites = JSON.parse(JSON.stringify(sites));
    tempSites[index].name = val;
    setSites(tempSites);
  };

  const handleOnChangeURL = (val) => {
    const tempSites = JSON.parse(JSON.stringify(sites));
    tempSites[index].url = val;
    setSites(tempSites);
  };

  const getErrorAdornment = (item) => {
    if (!!errors[`sites_${index}_${item}`]) {
      return (
        <InputAdornment position="end">
          <ErrorIcon style={{ color: colors.siteErrorIcon }} />
        </InputAdornment>
      );
    } else return null;
  };

  return (
    <Box
      sx={{
        width: "344px",
        minHeight: "166px",
        backgroundColor: colors.siteListBg,
        marginBottom: "16px",
        borderRadius: "8px",
        padding: "16px 16px 16px 16px",
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ display: "inline-flex", height: "24px" }}>
          <Typography
            sx={{
              color: colors.siteListTitle,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: "0.01px",
              lineHeight: "24px",
              display: "inline-flex",
              width: "320px",
            }}
          >
            {intl.formatMessage({ id: "site" }) + " " + siteCount}
          </Typography>
          <Tooltip
            title={intl.formatMessage({ id: "remove" })}
            arrow
            placement={"bottom-end"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: colors.tooltipBg2,
                  color: colors.tooltipFont,
                  fontFamily: "NotoSans-Medium",
                  fontSize: "16px",
                  fontWeight: 500,
                  letterSpacing: "0.01px",
                  lineHeight: "17px",
                  padding: "5px 12px",
                },
              },
              arrow: {
                sx: {
                  color: colors.tooltipBg2,
                },
              },
            }}
          >
            <IconButton
              onClick={() => handleRemoveSite(index)}
              sx={{ display: "inline-flex", py: "4px", px: "2px" }}
            >
              <CloseIcon24p alt={"close"} fill={colors.iconColor} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <BasicTextFieldWithoutForm
          label={intl.formatMessage({ id: "enter_site_name" })}
          type={"text"}
          variant={"outlined"}
          InputProps={{
            ...textFieldInputProps,
            endAdornment: getErrorAdornment("name"),
          }}
          InputLabelProps={inputLabelProps}
          handleOnChange={handleOnChangeName}
          value={site.name}
          errors={errors}
          formItemLabel={`sites_${index}_name`}
          maxChars={30}
        />
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <BasicTextFieldWithoutForm
          label={"URL"}
          type={"text"}
          variant={"outlined"}
          InputProps={{
            ...textFieldInputProps,
          }}
          InputLabelProps={inputLabelProps}
          handleOnChange={handleOnChangeURL}
          value={site.url}
          errors={errors}
          formItemLabel={`sites_${index}_url`}
        />
      </Box>
    </Box>
  );
};

const SitesListComponent = (props) => {
  const { form, selectedLocationForEdit, allDeviceData } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [sites, setSites] = useState(form.getValues("sites") || []);
  let siteCount = 1;
  const { colors }: any = useContext(DarkModeContext);

  const handleAddMoreSite = () => {
    const tempSites = JSON.parse(JSON.stringify(sites));
    tempSites.push({
      name: "",
      url: "",
      status: ACTIVE,
    });
    setSites(tempSites);
  };

  const handleRemoveSite = (index) => {
    const selectedSite = selectedLocationForEdit?.sites[index];
    let isDeviceInSite = false;

    if (!!selectedSite) {
      // if the site already exists, check if there's any device in the site
      isDeviceInSite = isDeviceInSites([selectedSite], allDeviceData);
    }

    if (isDeviceInSite) {
      enqueueSnackbar(intl.formatMessage({ id: "error_unable_delete" }), {
        variant: "error",
      });
      return;
    } else {
      form.clearErrors();
      const tempSites = JSON.parse(JSON.stringify(sites));
      tempSites.splice(index, 1);
      setSites(tempSites);
    }
  };

  useEffect(() => {
    form.setValue("sites", sites);
  }, [sites]);

  return (
    <Box sx={{ marginTop: "26px" }}>
      <Box sx={{ width: "100%" }}>
        {sites.map((site, index) => {
          if (site.status === ACTIVE) {
            return (
              <SiteComponent
                key={"site_" + index}
                sites={sites}
                setSites={setSites}
                index={index}
                siteCount={siteCount++}
                handleRemoveSite={handleRemoveSite}
                site={site}
                form={form}
                intl={intl}
              />
            );
          }
        })}
      </Box>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <BasicButton onClick={handleAddMoreSite} variant={"text"}>
          <Box sx={{ display: "inline-flex" }}>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "10px",
                backgroundColor: colors.datwylerTeal,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AddIcon />
            </Box>
          </Box>
          <Box sx={{ display: "inline-flex", marginLeft: "8px" }}>
            <Typography
              sx={{
                color: colors.datwylerTeal,
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
              }}
            >
              {intl.formatMessage({ id: "add_a_site" })}
            </Typography>
          </Box>
        </BasicButton>
      </Box>
    </Box>
  );
};

export default SitesListComponent;
