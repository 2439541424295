import React, { useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  CommonStyles,
  GraphQLApiProvider,
  DarkModeProvider,
  ApiProvider,
  UserProvider,
} from "@datwyler/shared-components";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);
  return (
    <React.StrictMode>
      <GraphQLApiProvider uri={props.GRAPHQL_URL}>
        <ApiProvider uri={props.REST_URL}>
          <BrowserRouter>
            <TranslationProvider locale={locale}>
              <DarkModeProvider>
                <CommonStyles>
                  <SnackBarProvider>
                    <UserProvider userProviderUri={props.SECURED_GATEWAY_URL}>
                      <App />
                    </UserProvider>
                  </SnackBarProvider>
                </CommonStyles>
              </DarkModeProvider>
            </TranslationProvider>
          </BrowserRouter>
        </ApiProvider>
      </GraphQLApiProvider>
    </React.StrictMode>
  );
}
