export const typographyStyle = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "16px",
  maxWidth: "165px",
  textTransform: "capitalize",
};

export const typography2Style = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "16px",
  maxWidth: "160px",
};

export const labelBoxStyle = {
  display: "inline-flex",
  width: "128px",
  marginRight: "24px",
};
export const statusLabelBoxStyle = {
  display: "inline-flex",
  width: "165px",
  marginRight: "24px",
  wordWrap: "break-word",
};
export const detailsBoxStyle = {
  display: "inline-flex",
  maxWidth: "160px",
  wordWrap: "break-word",
};
export const rowStyle = { marginBottom: "16px" };
