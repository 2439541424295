import { useContext } from "react";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DarkModeContext,
  ArrowRightIcon,
  ArrowOutwardIcon,
} from "@datwyler/shared-components";

const CustomDialogTitle = (props) => {
  const { site = {}, handleBack, devices = [] } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { colors }: any = useContext(DarkModeContext);

  const handleGoToDevice = () => {
    navigate("/device-monitoring/deviceDetails/", {
      state: {
        ...location.state,
        device: devices[0],
        devices: devices,
      },
    });
  };

  return (
    <DialogTitle
      sx={{
        paddingTop: "24px",
        paddingBottom: "16px",
        px: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box>
        <Box
          sx={{
            transform: "rotate(-180deg)",
            marginRight: "20px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={handleBack}
        >
          <ArrowRightIcon
            fill={colors.iconColor}
            width={"24px"}
            height={"24px"}
          />
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: "16px",
            fontWeight: "600",
            letterSpacing: "0.01px",
            lineHeight: "22px",
            width: "301px",
            color: colors.subTitle,
          }}
        >
          {site?.name}
        </Typography>
      </Box>
      {devices.length > 0 && (
        <Box>
          <Tooltip
            title={"Go to Device"}
            arrow
            placement={"bottom"}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: colors.tooltipBg,
                  color: colors.tooltipFont,
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: "-0.2px",
                  lineHeight: "19px",
                },
              },
              arrow: {
                sx: {
                  color: colors.tooltipBg,
                },
              },
            }}
          >
            <IconButton
              onClick={handleGoToDevice}
              sx={{ display: "inline-flex", py: "4px", px: "2px" }}
            >
              <ArrowOutwardIcon fill={colors.iconColor} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
