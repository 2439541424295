import { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import DetailsContentWrapper from "../../utils/DetailsContentWrapper";
import StyleContext from "../../../context/StyleContext";
import CustomDialogTitle from "./components/CustomDialogTitle";
import { ACTIVE } from "../../utils/constants";
import { getScrollBarSx, DarkModeContext } from "@datwyler/shared-components";

import SiteComponent from "./components/SiteComponent";

const LocationDetails = (props) => {
  const {
    isOpen,
    handleClose,
    location,
    handleViewSiteDetails,
    handleEditLocation,
    canManageSite,
  } = props;
  const { leftMargin }: any = useContext(StyleContext);
  const { colors }: any = useContext(DarkModeContext);

  const isAnySiteActive = () => {
    return location?.sites?.find((site) => site.status === ACTIVE);
  };

  return (
    <DetailsContentWrapper
      isOpen={isOpen}
      handleClose={handleClose}
      leftMargin={leftMargin}
    >
      <CustomDialogTitle
        location={location}
        handleEditLocation={handleEditLocation}
        handleCloseLocationModal={handleClose}
        canManageSite={canManageSite}
      />
      {isAnySiteActive() && (
        <Divider sx={{ borderColor: colors.dividerColor }} />
      )}
      <DialogContent
        sx={{
          paddingBottom: "8px",
          ...getScrollBarSx(colors),
        }}
      >
        {location?.sites?.map((site) => {
          if (site.status === ACTIVE) {
            return (
              <SiteComponent
                key={site.name}
                site={site}
                handleViewSiteDetails={handleViewSiteDetails}
              />
            );
          }
        })}
      </DialogContent>
    </DetailsContentWrapper>
  );
};

export default LocationDetails;
