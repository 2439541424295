import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import AddAttachmentDialog from "../components/Attachments/Dialogs/AddAttachmentDialog";
import AttachmentInfoDialog from "../components/Attachments/Dialogs/AttachmentInfoDialog";
import Filters from "../components/Attachments/Filters";
import AttachmentsTable from "../components/Attachments/AttachmentsTable";
import { ACTIVE, allowedExtensions } from "../components/utils/constants";
import DeleteAttachmentDialog from "../components/Attachments/Dialogs/DeleteAttachmentDialog";
import {
  useLocation,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  TenantIdContext,
  DarkModeContext,
  useAttachment,
  useSnackbar,
} from "@datwyler/shared-components";

const filterDefaults = {
  name: "",
  sites: [],
  format: [],
};

const Attachments = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const { isFetchLoading, fetchLocationData, fetchLocations } = useLocation();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const intl = useIntl();
  const [isAddAttachmentDialogOpen, setIsAddAttachmentDialogOpen] =
    useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [attachmentInfo, setAttachmentInfo] = useState(null);
  const { colors }: any = useContext(DarkModeContext);
  const {
    isFetchAttachmentsLoading,
    fetchAttachmentsData,
    fetchAttachments,
    updateAttachment,
    updateAttachmentResponseData,
    resetUpdateAttachmentData,
    isUpdateAttachmentLoading,
  } = useAttachment();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState(filterDefaults);

  useEffect(() => {
    getAttachmentsData();
  }, [filters, page, rowsPerPage]);

  useEffect(() => {
    setIsLoading(
      isFetchLoading ||
        isFetchAttachmentsLoading ||
        isUpdateAttachmentLoading ||
        false
    );
  }, [isFetchLoading, isFetchAttachmentsLoading, isUpdateAttachmentLoading]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantId },
    });
  }, [tenantId]);

  useEffect(() => {
    if (fetchAttachmentsData?.attachments) {
      setTotalRows(fetchAttachmentsData.attachments.page?.totalElements);

      if (fetchAttachmentsData.attachments.attachments?.length < 1) {
        enqueueSnackbar(intl.formatMessage({ id: "no_data" }), {
          variant: "warning",
        });
      }
    }
  }, [fetchAttachmentsData]);

  const getAttachmentsData = () => {
    const filtersToSend = [];

    if (filters.name) filtersToSend.push(`name:${filters.name}`);

    if (filters.sites.length > 0) {
      let sitesTosend = "";
      filters.sites?.forEach((site, index) => {
        if (index === 0) sitesTosend = sitesTosend + `siteId:${site}`;
        else sitesTosend = sitesTosend + `|siteId:${site}`; // Add OR if more than 1
      });

      filtersToSend.push(sitesTosend);
    }

    if (filters.format.length > 0) {
      let formatTosend = "";
      filters.format?.forEach((format, index) => {
        if (index === 0) formatTosend = formatTosend + `filename:.${format}`;
        else formatTosend = formatTosend + `|filename:.${format}`; // Add OR if more than 1
      });

      filtersToSend.push(formatTosend);
    }

    filtersToSend.push(`status:` + ACTIVE);

    fetchAttachments({
      variables: {
        tenantId: tenantId,
        page: page,
        size: rowsPerPage,
        sort: ['createdOn,desc'],
        filter: filtersToSend,
      },
    });
  };

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Filters
          locations={fetchLocationData?.locations}
          allowedExtensions={allowedExtensions}
          filterDefaults={filterDefaults}
          setFilters={setFilters}
        />
      </>
    );
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Attachments"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "attachments" })}
        LowerComponent={getLowerComponent()}
        addButton={{
          label: intl.formatMessage({ id: "add_attachment" }),
          onClick: () => setIsAddAttachmentDialogOpen(true),
        }}
      />
      {fetchAttachmentsData?.attachments?.attachments?.length > 0 && (
        <AttachmentsTable
          attachmentsData={fetchAttachmentsData?.attachments?.attachments}
          locations={fetchLocationData?.locations}
          setAttachmentInfo={setAttachmentInfo}
          setAttachmentToDelete={setAttachmentToDelete}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={totalRows}
          tenantId={tenantId}
        />
      )}
      <AddAttachmentDialog
        isOpen={isAddAttachmentDialogOpen}
        closeDialog={() => setIsAddAttachmentDialogOpen(false)}
        locations={fetchLocationData?.locations}
        allowedExtensions={allowedExtensions}
        tenantId={tenantId}
        getAttachmentsData={getAttachmentsData}
      />
      <AttachmentInfoDialog
        isOpen={!!attachmentInfo}
        closeDialog={() => setAttachmentInfo(null)}
        attachmentInfo={attachmentInfo}
      />
      <DeleteAttachmentDialog
        isOpen={!!attachmentToDelete}
        closeDialog={() => {
          setAttachmentToDelete(null);
        }}
        attachmentToDelete={attachmentToDelete}
        updateAttachment={updateAttachment}
        updateAttachmentResponseData={updateAttachmentResponseData}
        resetUpdateAttachmentData={resetUpdateAttachmentData}
        getAttachmentsData={getAttachmentsData}
      />
    </Box>
  );
};

export default Attachments;
