import { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import {
  BasicButton,
  DarkModeContext,
  DeleteIcon,
  CloseIcon24p,
} from "@datwyler/shared-components";

const CustomDialogActions = (props) => {
  const intl = useIntl();
  const { darkMode, colors }: any = useContext(DarkModeContext);
  const [iconColor, setIconColor] = useState(colors.red500);
  const {
    form,
    handleSubmit,
    isEdit,
    handleDeleteLocation,
    selectedLocationForEdit,
  } = props;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [name, country, longitude, latitude] = form.watch([
    "name",
    "country",
    "longitude",
    "latitude",
  ]);

  useEffect(() => {
    if (name && country && longitude && latitude) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [name, country, longitude, latitude]);

  const typoStyle = {
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0",
    lineHeight: "20px",
  };

  const handleClickDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  return (
    <DialogActions
      sx={{
        justifyContent: isEdit ? "flex-start" : "flex-end",
        paddingBottom: "24px",
      }}
    >
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        handleDelete={handleDeleteLocation}
        selectedLocationForEdit={selectedLocationForEdit}
      />
      {isEdit && (
        <Box sx={{ marginLeft: "16px", marginRight: "65px" }}>
          <BasicButton
            onClick={handleClickDelete}
            variant={"outlined"}
            onMouseEnter={() => {
              setIconColor(darkMode ? colors.white : colors.red500);
            }}
            onMouseLeave={() => {
              setIconColor(colors.red500);
            }}
            sx={{
              borderColor: colors.red500,
              color: colors.red500,
              width: "172px",
              height: "44px",

              ":hover": {
                bgcolor: darkMode ? colors.red400 : colors.red50,
                color: darkMode ? colors.white : colors.red500,
                border: `1px solid ${colors.red500}`,
              },
            }}
          >
            <DeleteIcon fill={iconColor} />
            <Typography sx={typoStyle} style={{ marginLeft: "10px" }}>
              {intl.formatMessage({ id: "delete_location" })}
            </Typography>
          </BasicButton>
        </Box>
      )}
      <Box sx={{ marginRight: "16px" }}>
        <BasicButton
          onClick={handleSubmit}
          variant={"contained"}
          disabled={isSaveDisabled}
          sx={{ width: "131px", height: "44px" }}
        >
          <Typography sx={typoStyle}>
            {isEdit
              ? intl.formatMessage({ id: "save_changes" })
              : intl.formatMessage({ id: "create_new" })}
          </Typography>
        </BasicButton>
      </Box>
    </DialogActions>
  );
};

const DeleteDialog = (props) => {
  const {
    isOpen = false,
    setIsOpen,
    handleDelete,
    selectedLocationForEdit,
  } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(selectedLocationForEdit);
    handleClose();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "delete" }) +
            ' "' +
            selectedLocationForEdit?.name +
            '"?'}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {intl.formatMessage({ id: "confirm_delete_location" })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleConfirmDelete}
            variant={"contained"}
            sx={{
              backgroundColor: colors.red500,
              ":hover": {
                bgcolor: colors.red500,
              },
            }}
          >
            {intl.formatMessage({ id: "delete" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogActions;
