import { useState, MouseEvent, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  BasicTextFieldWithoutForm,
} from "@datwyler/shared-components";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const { locations, filterDefaults, allowedExtensions, setFilters } = props;
  const [interimFilters, setInterimFilters] = useState(filterDefaults);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocations, setselectedLocations] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (locations) {
      const locationOptions = locations.map((location, index) => {
        return {
          priority: index,
          label: location.name,
          value: location.id,
        };
      });
      setLocationOptions(locationOptions);
    }
  }, [locations]);

  useEffect(() => {
    if (selectedLocations) {
      let allSites = [];
      const allSelectedLocationDetails = selectedLocations.map((id) => {
        return locations.find((loc) => loc.id === id);
      });

      allSelectedLocationDetails.forEach((location) => {
        if (location.sites) allSites = allSites.concat(location.sites);
      });

      const siteOptions = allSites.map((site, index) => {
        return {
          priority: index,
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(siteOptions);
    }
  }, [selectedLocations]);

  useEffect(() => {
    const formatOptions = allowedExtensions.map((ext, index) => {
      return {
        priority: index,
        label: ext.replace(".", ""),
        value: ext.replace(".", ""),
      };
    });

    setFormatOptions(formatOptions);
  }, [allowedExtensions]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLocation = (location: any) => {
    setselectedLocations(location);
  };

  const handleChangeSite = (sites: Array<string>) => {
    setInterimFilters({ ...interimFilters, sites: sites });
  };

  const handleOnChangeName = (value) => {
    setInterimFilters({ ...interimFilters, name: value });
  };

  const handleChangeFormat = (format: Array<string>) => {
    setInterimFilters({ ...interimFilters, format: format });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setInterimFilters(filterDefaults);
    setselectedLocations([]);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeLocation}
                  values={selectedLocations}
                  label={intl.formatMessage({ id: "location" })}
                  menuItems={locationOptions}
                />
              </Box>
              {selectedLocations.length > 0 && (
                <Box sx={{ width: "230px", marginTop: "16px" }}>
                  <BasicSelectWithCheckBox
                    onChange={handleChangeSite}
                    values={interimFilters.sites}
                    label={intl.formatMessage({ id: "sites" })}
                    menuItems={siteOptions}
                  />
                </Box>
              )}
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicTextFieldWithoutForm
                  label={intl.formatMessage({ id: "attachment_name" })}
                  type={"text"}
                  formItemLabel={"name"}
                  handleOnChange={handleOnChangeName}
                  variant={"outlined"}
                  size={"small"}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      letterSpacing: "0.01px",
                      top: "3px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      height: "44px",
                    },
                  }}
                  value={interimFilters.name}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeFormat}
                  values={interimFilters.format}
                  label={intl.formatMessage({ id: "select_format" })}
                  menuItems={formatOptions}
                />
              </Box>
              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                {(interimFilters.sites.length > 0 ||
                  !!interimFilters.name ||
                  interimFilters.format.length > 0) && (
                  <BasicButton
                    variant={"contained"}
                    onClick={applyFilters}
                    sx={{ marginLeft: "20px", padding: "12px 16px" }}
                  >
                    {intl.formatMessage({ id: "apply" })}
                  </BasicButton>
                )}
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;
