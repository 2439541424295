import { useContext, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import {
  textFieldInputProps,
  inputLabelProps,
  getInputRulesRequired,
  getInputRulesLongitude,
  getInputRulesLatitude,
} from "../utils";
import SitesListComponent from "./SitesListComponent";
import {
  BasicTextField,
  BasicSelectWithCheckMark,
  getScrollBarSx,
  DarkModeContext,
} from "@datwyler/shared-components";

const CustomDialogContent = (props) => {
  const {
    form,
    countries,
    isCountriesLoading,
    selectedLocationForEdit,
    allDeviceData,
  } = props;
  const intl = useIntl();
  const [countryOptions, setCountryOptions] = useState([]);
  const { colors }: any = useContext(DarkModeContext);

  const getCountryOptions = () => {
    const tempOptions = [];
    let count = 0;
    countries?.forEach((country) => {
      tempOptions.push({
        priority: count++,
        label: country.name,
        value: country.id,
      });
    });

    return tempOptions;
  };

  useMemo(() => {
    if (countries) setCountryOptions(getCountryOptions());
  }, [countries]);

  return (
    <DialogContent
      sx={{
        paddingBottom: "8px",
        ...getScrollBarSx(colors),
      }}
    >
      <Box>
        <Box sx={{ width: "376px" }}>
          <BasicTextField
            label={intl.formatMessage({ id: "enter_location_name" }) + "*"}
            type={"text"}
            form={form}
            formItemLabel={"name"}
            rules={{
              ...getInputRulesRequired(intl),
              maxLength: {
                value: 30,
                message: intl.formatMessage({ id: "validation_maxlength" }),
              },
            }}
            variant={"outlined"}
            InputProps={textFieldInputProps}
            InputLabelProps={inputLabelProps}
          />
        </Box>
        <Box sx={{ width: "376px", height: "48px", marginTop: "16px" }}>
          <BasicSelectWithCheckMark
            menuItems={countryOptions}
            form={form}
            formItemLabel={"country.id"}
            label={intl.formatMessage({ id: "select_country" }) + "*"}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({ id: "validation_required" }),
              },
            }}
            SelectDisplayProps={{
              paddingTop: "14px",
              paddingBottom: "14px",
              paddingLeft: "16px",
              height: "20px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "27px",
            }}
            menuPropsSx={{
              maxHeight: "256px",
              maxWidth: "376px",
              marginTop: "8px",
            }}
            isLoading={isCountriesLoading}
          />
        </Box>
        <Box sx={{ width: "376px", marginTop: "16px" }}>
          <BasicTextField
            label={intl.formatMessage({ id: "enter_longitude" }) + "*"}
            type={"text"}
            form={form}
            formItemLabel={"longitude"}
            rules={{
              ...getInputRulesRequired(intl),
              ...getInputRulesLongitude(intl),
            }}
            variant={"outlined"}
            InputProps={textFieldInputProps}
            InputLabelProps={inputLabelProps}
          />
        </Box>
        <Box sx={{ width: "376px", marginTop: "16px" }}>
          <BasicTextField
            label={intl.formatMessage({ id: "enter_latitude" }) + "*"}
            type={"text"}
            form={form}
            formItemLabel={"latitude"}
            rules={{
              ...getInputRulesRequired(intl),
              ...getInputRulesLatitude(intl),
            }}
            variant={"outlined"}
            InputProps={textFieldInputProps}
            InputLabelProps={inputLabelProps}
          />
        </Box>
        <Divider sx={{ marginTop: "24px", borderColor: colors.dividerColor }} />
      </Box>
      <SitesListComponent
        form={form}
        selectedLocationForEdit={selectedLocationForEdit}
        allDeviceData={allDeviceData}
      />
    </DialogContent>
  );
};

export default CustomDialogContent;
