import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DarkModeContext, ArrowRightIcon } from "@datwyler/shared-components";

const SiteComponent = (props) => {
  const { site, handleViewSiteDetails } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Box
      onClick={() => handleViewSiteDetails(site)}
      sx={{
        width: "346px",
        backgroundColor: colors.locationDetailsSiteBtnBg,
        borderRadius: "8px",
        marginBottom: "8px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Box sx={{ display: "inline-flex" }}>
        <Box sx={{ py: "10px", px: "16px", width: "280px" }}>
          <Typography
            sx={{
              color: colors.subTitle,
              fontFamily: "NotoSans-Medium",
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: "0.01px",
              lineHeight: "20px",
            }}
          >
            {site.name}
          </Typography>
          <Typography
            sx={{
              color: colors.fontTitle,
              fontFamily: "NotoSans-Regular",
              fontSize: "12px",
              letterSpacing: "0.01px",
              lineHeight: "16px",
            }}
          >
            {site.url}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "inline-flex" }}>
        <Box sx={{ paddingTop: "10px" }}>
          <ArrowRightIcon fill={colors.iconColor} />
        </Box>
      </Box>
    </Box>
  );
};

export default SiteComponent;
