import { useContext } from "react";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  EditIcon,
  DarkModeContext,
  AlarmBullet,
} from "@datwyler/shared-components";

const CustomDialogTitle = (props) => {
  const {
    location,
    handleEditLocation,
    handleCloseLocationModal,
    canManageSite,
  } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const descriptionFontStyles = {
    color: colors.fontTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.01px",
    lineHeight: "16px",
  };

  const handleEdit = () => {
    handleCloseLocationModal();
    handleEditLocation(location);
  };

  return (
    <DialogTitle
      sx={{
        paddingTop: "24px",
        paddingBottom: "16px",
        px: "24px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={{ lineHeight: "24px", height: "24px" }}>
          <Box
            sx={{
              display: "inline-flex",
              width: "322px",
              verticalAlign: "top",
            }}
          >
            <Typography
              sx={{
                fontFamily: "NotoSans-SemiBold",
                fontSize: 16,
                display: "inline-flex",
                marginRight: "16px",
                color: colors.subTitle,
              }}
            >
              {location?.name}
            </Typography>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              <AlarmBullet severity={location?.highestSeverity} />
            </Box>
          </Box>
          {canManageSite && (
            <Box
              sx={{ display: "inline-flex", cursor: "pointer" }}
              onClick={handleEdit}
            >
              <EditIcon fill={colors.iconColor} />
            </Box>
          )}
        </Box>
        <Box sx={{ lineHeight: "16px", marginTop: "16px" }}>
          <Box sx={{ display: "inline-flex", width: "98px" }}>
            <Typography sx={descriptionFontStyles}>
              {intl.formatMessage({ id: "location" })}
            </Typography>
          </Box>
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={descriptionFontStyles}>
              {location?.country?.name}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ lineHeight: "16px", marginTop: "10px" }}>
          <Box sx={{ display: "inline-flex", width: "98px" }}>
            <Typography sx={descriptionFontStyles}>
              {intl.formatMessage({ id: "coordinates" })}
            </Typography>
          </Box>
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={descriptionFontStyles}>
              {location?.latitude + "," + location?.longitude}
            </Typography>
          </Box>
        </Box>
      </Box>
    </DialogTitle>
  );
};

export default CustomDialogTitle;
