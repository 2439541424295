import { useContext } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { CloseIcon24p, DarkModeContext } from "@datwyler/shared-components";

const CustomDialogTitle = (props) => {
  const { handleCloseModal, selectedLocationForEdit } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  return (
    <DialogTitle sx={{ paddingTop: "24px", paddingBottom: "16px" }}>
      <Typography
        sx={{
          fontFamily: "NotoSans-SemiBold",
          fontSize: 16,
          color: colors.subTitle,
        }}
      >
        {selectedLocationForEdit
          ? selectedLocationForEdit.name
          : intl.formatMessage({ id: "add_location" })}
      </Typography>
      <IconButton
        onClick={handleCloseModal}
        sx={{ position: "absolute", right: 16, top: 16 }}
      >
        <CloseIcon24p fill={colors.iconColor} />
      </IconButton>
    </DialogTitle>
  );
};

export default CustomDialogTitle;
