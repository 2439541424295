import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  typographyStyle,
  typography2Style,
  labelBoxStyle,
  detailsBoxStyle,
  rowStyle,
} from "../utils";
import { DarkModeContext } from "@datwyler/shared-components";

const openInNewTab = (link) => {
  window.open(link, "_blank");
};

const DetailTab = (props) => {
  const { siteDetails } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const font1Style = { ...typographyStyle, color: colors.siteDetailsFont1 };
  const font2Style = { ...typography2Style, color: colors.siteDetailsFont2 };

  const linkStyle = {
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: 500,
    letterSpacing: "0px",
    lineHeight: "20px",
    color: colors.siteDetailsDetailTabLink,
    marginLeft: "4px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline " + font2Style.color,
    },
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box sx={rowStyle}>
        <Box sx={labelBoxStyle}>
          <Typography sx={font1Style}>
            {intl.formatMessage({ id: "location" })}
          </Typography>
        </Box>
        <Box sx={detailsBoxStyle}>
          <Typography sx={font2Style}>{siteDetails?.location?.name}</Typography>
        </Box>
      </Box>
      <Box sx={rowStyle}>
        <Box sx={labelBoxStyle}>
          <Typography sx={font1Style}>{"URL"}</Typography>
        </Box>
        <Box sx={{ ...detailsBoxStyle, maxWidth: "unset" }}>
          <Typography sx={font2Style}>{siteDetails?.site?.url}</Typography>
          {siteDetails?.site?.url && (
            <Typography
              sx={linkStyle}
              onClick={() => openInNewTab(siteDetails.site.url)}
            >
              {`(${intl.formatMessage({ id: "view" })})`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={rowStyle}>
        <Box sx={labelBoxStyle}>
          <Typography sx={font1Style}>
            {intl.formatMessage({ id: "longitude" })}
          </Typography>
        </Box>
        <Box sx={detailsBoxStyle}>
          <Typography sx={font2Style}>
            {siteDetails?.location?.longitude}
          </Typography>
        </Box>
      </Box>
      <Box sx={rowStyle}>
        <Box sx={labelBoxStyle}>
          <Typography sx={font1Style}>
            {intl.formatMessage({ id: "latitude" })}
          </Typography>
        </Box>
        <Box sx={detailsBoxStyle}>
          <Typography sx={font2Style}>
            {siteDetails?.location?.latitude}
          </Typography>
        </Box>
      </Box>
      <Box sx={rowStyle}>
        <Box sx={labelBoxStyle}>
          <Typography sx={font1Style}>
            {intl.formatMessage({ id: "number_of_devices" })}
          </Typography>
        </Box>
        <Box sx={detailsBoxStyle}>
          <Typography sx={font2Style}>
            {siteDetails?.details?.numDevices || 0}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailTab;
