import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { handleDownload } from "../../utils/utils";
import {
  BasicDataTable,
  BasicPagination,
  getRequiredDateFormat,
  getLocationFromSiteId,
  DarkModeContext,
  ApiContext,
  useSnackbar,
} from "@datwyler/shared-components";

const headers = [
  "location",
  "site",
  "attachment_name",
  "format",
  "date",
  "actions",
];

const columnConfigs = {
  location: {
    colspan: 2,
    isCentralized: true,
  },
  site: {
    colspan: 2,
    isCentralized: false,
  },
  attachment_name: {
    colspan: 2,
    isCentralized: false,
  },
  format: {
    colspan: 2,
    isCentralized: false,
  },
  date: {
    colspan: 2,
    isCentralized: false,
  },
  actions: {
    colspan: 1,
    isCentralized: true,
  },
};

const AttachmentsTable = (props) => {
  const {
    locations = [],
    attachmentsData,
    setAttachmentInfo,
    setAttachmentToDelete,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    tenantId,
  } = props;
  const [tableData, setTableData] = useState([[]]);
  const { uri, apiInstance }: any = useContext(ApiContext);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (attachmentsData) {
      setTableData(getData());
    }
  }, [attachmentsData]);

  useEffect(() => {
    if (locations.length > 0) {
      setTableData(getData());
    }
  }, [locations]);

  useEffect(() => {
    setTableData(getData());
  }, [page, rowsPerPage]);

  const getMoreButtonMenu = (attachment) => {
    return [
      {
        label: intl.formatMessage({ id: "details" }),
        value: null,
        onClick: () => {
          setAttachmentInfo(attachment);
        },
      },
      {
        label: intl.formatMessage({ id: "download" }),
        value: null,
        onClick: () => {
          handleDownload(
            attachment,
            apiInstance,
            uri,
            tenantId,
            enqueueSnackbar
          );
        },
      },
      {
        label: intl.formatMessage({ id: "delete" }),
        value: null,
        menuItemFontStyle: {
          color: colors.red800,
          fontFamily: "NotoSans-Regular",
          fontSize: "14px",
          letterSpacing: 0,
          lineHeight: "20px",
          "&:hover": {
            color: colors.red800,
          },
        },
        onClick: () => {
          setAttachmentToDelete(attachment);
        },
      },
    ];
  };

  const getData = () => {
    const data = [];

    if (attachmentsData) {
      attachmentsData.forEach((attachment) => {
        const location = getLocationFromSiteId(locations, attachment.site.id);
        const fileExtension = attachment?.filename?.split(".").pop();

        data.push({
          location: location?.name || " ",
          site: attachment.site?.name || " ",
          attachment_name: attachment.name,
          format: fileExtension,
          date: getRequiredDateFormat(attachment.createdOn, "DD/MM/YYYY HH:mm"),
          additionalInfo: attachment,
          moreButtonMenuItems: getMoreButtonMenu(attachment),
        });
      });
    }

    return data;
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        columnConfigs={columnConfigs}
        isHideCheckbox={true}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default AttachmentsTable;
