export const handleDownload = (
  attachment,
  apiInstance,
  uri,
  tenantId,
  enqueueSnackbar
) => {
  const attachmentId = attachment?.id;
  const siteId = attachment?.site?.id;

  if (attachmentId && siteId) {
    const url =
      uri +
      "/tenants/" +
      tenantId +
      "/sites/" +
      siteId +
      "/attachments/" +
      attachmentId;

    apiInstance
      .get(url, { responseType: "blob" })
      .then((response) => {
        const fileName = attachment.filename;
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  }
};
