import { useContext } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { ACTIVE } from "../utils/constants";
import {
  BasicSelectWithoutForm,
  DarkModeContext,
} from "@datwyler/shared-components";

const LocationFilters = (props) => {
  const { locations, filters, setFilters } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const getCountryOptions = () => {
    const tempOptions = [
      {
        priority: 0,
        label: intl.formatMessage({ id: "select_country" }),
        value: "",
        isHide: true,
      },
      {
        priority: 1,
        label: intl.formatMessage({ id: "all" }),
        value: "all",
        isHide: false,
      },
    ];
    const countriesAdded = [];
    let count = 1;
    locations?.forEach((loc) => {
      // check if country already exists
      if (loc.status === ACTIVE && !countriesAdded.includes(loc.country.id)) {
        countriesAdded.push(loc.country.id);
        tempOptions.push({
          priority: ++count,
          label: loc.country.name,
          value: loc.country.id,
          isHide: false,
        });
      }
    });

    return tempOptions;
  };

  const handleOnSelectCountry = (e) => {
    setFilters({ country: e.target.value });
  };

  return (
    <Box
      sx={{
        marginLeft: "22px",
        display: "inline-flex",
        position: "fixed",
        zIndex: 10,
        float: "right",
        minWidth: 140,
      }}
    >
      <BasicSelectWithoutForm
        menuItems={getCountryOptions()}
        rules={{
          required: {
            value: true,
            message: intl.formatMessage({ id: "validation_required" }),
          },
        }}
        onChange={handleOnSelectCountry}
        value={filters.country}
        menuItemWidth={"239px"}
        SelectDisplayProps={{
          minWidth: "152px",
          padding: "12px 16px",
          backgroundColor: colors.contentBg,
          minHeight: "unset",
        }}
        menuPropsSx={{
          marginTop: "8px",
          marginLeft: "45px",
        }}
        menuPaperSx={{
          minWidth: "250px",
        }}
      />
    </Box>
  );
};

export default LocationFilters;
