import { useState, useRef, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
  BasicTextField,
  BasicSelectWithCheckMark,
  DragAndDropFileUpload,
  ApiContext,
} from "@datwyler/shared-components";

const formDefaults = {
  siteId: "",
  name: "",
  description: "",
};
const inputLabelProps = {
  sx: {
    fontSize: "14px",
    letterSpacing: "0.01px",
    top: "3px",
  },
};

const InputProps = {
  sx: {
    height: "44px",
  },
};

const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
};

const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
};

const AddAttachmentDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    locations,
    allowedExtensions,
    tenantId,
    selectedSiteId,
    getAttachmentsData,
  } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();
  const inputRef = useRef(null);
  const [siteOptions, setSiteOptions] = useState([]);
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({
    defaultValues: {
      ...formDefaults,
      siteId: selectedSiteId ? selectedSiteId : "",
    },
  });
  const { uri, apiInstance }: any = useContext(ApiContext);

  const siteId = form.watch("siteId");
  const name = form.watch("name");
  const description = form.watch("description");

  useMemo(() => {
    let allSites = [];
    if (locations) {
      locations.forEach((location) => {
        if (location.sites) allSites = allSites.concat(location.sites);
      });

      const siteOptions = allSites.map((site, index) => {
        return {
          priority: index,
          label: site.name,
          value: site.id,
        };
      });

      setSiteOptions(siteOptions);
    }
  }, [locations]);

  const handleFileUpload = (file) => {
    // Check the file extension, currently only accept .bin firmware files
    const fileExtension = file?.name?.split(".").pop();

    if (fileExtension) {
      if (allowedExtensions.includes("." + fileExtension)) {
        // if correct, set the firmware file and return
        setFile(file);
        return;
      } else {
        enqueueSnackbar(
          intl.formatMessage({ id: "err_file_upload_ext" }) +
            " " +
            allowedExtensions.join(", "),
          {
            variant: "error",
          }
        );
        return;
      }
    }
    enqueueSnackbar(intl.formatMessage({ id: "err_file_upload" }), {
      variant: "error",
    });
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("file", file);

      // call api to upload firmware file
      const url =
        uri + "/tenants/" + tenantId + "/sites/" + siteId + "/attachments";
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      apiInstance
        .post(url, formData, config)
        .then((response) => {
          if (response?.status === 200)
            enqueueSnackbar(intl.formatMessage({ id: "upload_success" }), {
              variant: "success",
            });
          setFile(null);
          form.reset(formDefaults);
          getAttachmentsData();
          closeDialog();
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
    }
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "add_attachment" })}
        </Typography>
        <IconButton
          onClick={closeDialog}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent>
        <Box sx={{ paddingBottom: "24px" }}>
          {!selectedSiteId && (
            <Box>
              <BasicSelectWithCheckMark
                menuItems={siteOptions}
                form={form}
                formItemLabel={"siteId"}
                label={intl.formatMessage({ id: "select_site" }) + "*"}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
              />
            </Box>
          )}
          <Box sx={{ marginTop: "15px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "attachment_name" }) + "*"}
              type={"text"}
              form={form}
              formItemLabel={"name"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={InputProps}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "description" }) + "*"}
              type={"text"}
              form={form}
              formItemLabel={"description"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={InputProps}
            />
          </Box>
        </Box>
        <DragAndDropFileUpload
          inputRef={inputRef}
          isMultiple={false}
          fileTypes={allowedExtensions.join(",")}
          onFileUpload={handleFileUpload}
          file={file}
          setFile={setFile}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={closeDialog} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleUpload}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
            disabled={!siteId || !name || !description || !file}
          >
            {intl.formatMessage({ id: "upload" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttachmentDialog;
