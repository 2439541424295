import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { detailsBoxStyle, rowStyle } from "../utils";
import { useIntl } from "react-intl";
import { ACTIVE } from "../../../utils/constants";
import { DarkModeContext, AlarmBullet } from "@datwyler/shared-components";

const AlertTab = (props) => {
  const { siteDetails } = props;
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  const typeTypographyStyle = {
    width: "160px",
    color: colors.siteDetailsFont2,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  const dateTypographyStyle = {
    width: "60px",
    color: colors.siteDetailsFont1,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.02px",
    lineHeight: "16px",
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      {siteDetails?.devices.map((device) => {
        if (device.alarms) {
          const found = device.alarms.find((alarm) => alarm.status === ACTIVE);
          if (!!found) {
            return (
              <>
                <Typography
                  sx={{
                    color: colors.siteDetailsDeviceName,
                    fontFamily: "NotoSans-SemiBold",
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: "0.01px",
                    lineHeight: "20px",
                    marginBottom: "16px",
                  }}
                >
                  {device.name}
                </Typography>
                {device?.alarms?.map((alert, index) => {
                  if (alert.status === ACTIVE) {
                    const alertTime = new Date(alert.time * 1000);
                    return (
                      <Box
                        sx={{
                          ...rowStyle,
                          display: "flex",
                          alignItems: "center",
                        }}
                        key={"alert_" + index}
                      >
                        <AlarmBullet
                          severity={alert.severity}
                          width={7}
                          height={7}
                        />
                        <Box sx={detailsBoxStyle}>
                          <Typography sx={typeTypographyStyle}>
                            {alert?.values?.attribute_name
                              ? intl.formatMessage({
                                  id: alert.values.attribute_name,
                                })
                              : ""}
                          </Typography>
                        </Box>
                        <Box sx={{ ...detailsBoxStyle, marginRight: "49px" }}>
                          <Typography sx={dateTypographyStyle}>
                            {formatTime(alertTime.getHours()) +
                              ":" +
                              formatTime(alertTime.getMinutes()) +
                              ":" +
                              formatTime(alertTime.getSeconds())}
                          </Typography>
                        </Box>
                        <Box sx={detailsBoxStyle}>
                          <Typography sx={dateTypographyStyle}>
                            {formatTime(alertTime.getDate()) +
                              "/" +
                              formatTime(alertTime.getMonth() + 1) +
                              "/" +
                              formatTime(alertTime.getFullYear() % 100)}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                })}
              </>
            );
          }
        }
      })}
    </Box>
  );
};

const formatTime = (time) => {
  if (time < 10) {
    return "0" + time;
  }
  return time;
};

export default AlertTab;
